.searchbar {
  display: flex;
  flex-direction: row;
  border: 1px solid #979797;
  border-radius: 6px;
  max-width: 200px;
  width: 263px;
}
.searchbar input {
  padding: 10px;
  border: none;
  margin: 2px;
}
.searchbar .trigger {
  padding: 10px;
}
.searchbar .trigger img {
  width: 20px;
  height: 20px;
}

/*# sourceMappingURL=searchBar.css.map */
