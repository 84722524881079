
.next {
    margin-left: 10px;
}

.prev {
    margin-right: 10px;
}

.nav_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_wrapper img {
    width: 25px;
}