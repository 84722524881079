
.typeWriterTextWrapper {
    display: flex;
    flex-direction: row;
}

.blink-caret {
    display: flex;
    flex: 1;
    /* width: 10px;
    height: 10px;
    background-color: black; */

    margin-left: 5px;
    
    animation: blink-caret .5s step-end infinite;
}

@keyframes blink-caret {
    from, to { opacity: 0 }
    50% { opacity: .8 }
}