.calendar .callendar_wraper {
    width: 100%;
    min-width: 330px;
  }
  .calendar .callendar_wraper .month_year_wraper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .calendar .callendar_wraper .month_year_wraper .month, .calendar .callendar_wraper .month_year_wraper .year {
    padding: 0px 10px;
  }
  .calendar .callendar_wraper .days_label {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .calendar .callendar_wraper .days_label .item {
    text-align: center;
    padding: 10px;
  }
  .calendar .callendar_wraper .days_wraper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .calendar .callendar_wraper .days_wraper .item {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }
  .calendar .callendar_wraper .days_wraper .item:hover {
    background-color: lightyellow;
  }
  .calendar .callendar_wraper .days_wraper .past {
    color: lightgray;
  }
  .calendar .callendar_wraper .days_wraper .today {
    /* font-weight: bold; */
    /* border: 1px solid lightgray;
    border-radius: 20px; */
    text-decoration: underline;
    /* color: purple; */
  }